import React from "react"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"
import { DateTime } from "luxon"

const IdCell = cell => {
  return (
    <Link
      to={
        cell.value
          ? `https://check.diplom.mn/check/${cell.value}?channel=university-test-240130`
          : "#"
      }
      target="_blank"
      className="text-body fw-bold"
    >
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const StatusCell = cell => {
  return (
    <Badge
      className={
        "font-size-11 badge-soft-" +
        (cell.value === "new"
          ? "success"
          : "danger" && cell.value === ""
          ? "warning"
          : "danger")
      }
    >
      {cell.value}
    </Badge>
  )
}

export { IdCell, StatusCell }

const TextCell = cell => {
  return cell.value ? cell.value : ""
}
const DateCell = cell => {
  // Ensure cell.value is treated as a number, even if it comes as a string
  const timestamp = Number(cell.value)

  // Check if the conversion resulted in a valid number and is not NaN
  if (!isNaN(timestamp) && timestamp) {
    // Convert the Unix timestamp (assumed to be in seconds) to a Luxon DateTime object and format it
    return DateTime.fromSeconds(timestamp)
      .toUTC()
      .toISO({ includeOffset: true, suppressMilliseconds: true })
  } else {
    // Return an empty string or a placeholder if the input is not a valid timestamp
    return cell.value // or any placeholder like "Invalid date"
  }
}

const Img = cell => {
  return (
    <>
      {!cell.value ? (
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">
            {cell.data[0].name.charAt(0)}
          </span>
        </div>
      ) : (
        <div>
          <img className="rounded-circle avatar-xs" src={cell.value} alt="" />
        </div>
      )}
    </>
  )
}

export { TextCell, DateCell, Img }
