import React, { useEffect, useState, useRef, useMemo } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import {
  TextCell,
  Tags,
  Projects,
  DateCell,
  StatusCell,
} from "./organizationLogCol"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions"
import { isEmpty } from "lodash"
import { useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import TableContainer from "components/Common/TableContainer"
// import { addOrganization, listOrganizations } from "helpers/api_helper"

const OrganizationsList = props => {
  const [apiData, setApiData] = useState(null)
  const fetchCredits = async () => {
    try {
      // const r = await listOrganizations()
      const err = r
      if (err?.message != "error") {
        setApiData(r)
      }
    } catch (error) {
      return "error"
    }
  }

  useEffect(() => {
    fetchCredits()
  }, [])

  document.title = "Organization List | Diplom.mn"

  const dispatch = useDispatch()
  const [contact, setContact] = useState()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},
    // validationSchema: Yup.object({
    //   name: Yup.string().required("Please Enter Your Name"),
    //   designation: Yup.string().required("Please Enter Your Designation"),
    //   tags: Yup.array().required("Please Enter Tag"),
    //   email: Yup.string()
    //     .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
    //     .required("Please Enter Your Email"),
    //   projects: Yup.string().required("Please Enter Your Project"),
    // }),
    onSubmit: async values => {
      const r = await addOrganization(values)
      if (r) {
        const err = r?.result
        if (!err?.length) {
          toast.success("Organization added successfully")
        } else {
          toast.error(err)
        }
      }
      // if (isEdit) {
      //   const updateUser = {
      //     id: contact.id,
      //     name: values.name,
      //     designation: values.designation,
      //     tags: values.tags,
      //     email: values.email,
      //     projects: values.projects,
      //   }
      //   // update user
      //   dispatch(onUpdateUser(updateUser))
      //   setIsEdit(false)
      //   validation.resetForm()
      // } else {
      //   const newUser = {
      //     id: Math.floor(Math.random() * (30 - 20)) + 20,
      //     name: values["name"],
      //     designation: values["designation"],
      //     email: values["email"],
      //     tags: values["tags"],
      //     projects: values["projects"],
      //   }
      //   // save new user
      //   dispatch(onAddNewUser(newUser))
      //   validation.resetForm()
      // }
      // toggle()
    },
  })

  // const selectContactsState = state => state.contacts
  // const ContactsProperties = createSelector(selectContactsState, Contacts => ({
  //   users: Contacts.users,
  //   loading: Contacts.loading,

  // }))

  // const { users, loading } = useSelector(ContactsProperties)

  const [userList, setUserList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setLoading] = useState()

  const columns = useMemo(
    () => [
      {
        Header: "Transaction Date",
        accessor: "createTxTimestamp",
        filterable: true,
        Cell: cellProps => {
          return <DateCell {...cellProps} />
        },
      },
      {
        Header: "Logo",
        accessor: "logoUrl",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Organization Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Institution Id",
        accessor: "institutionId",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Institution Name",
        accessor: "institutionName",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
      {
        Header: "Description",
        accessor: "desc",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
      {
        Header: "Organization credit id",
        accessor: "orgCreditId",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "isActive",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <StatusCell {...cellProps} />
            </>
          )
        },
      },
    ],
    []
  )

  // useEffect(() => {
  //   if (users && !users.length) {
  //     dispatch(onGetUsers())
  //     setIsEdit(false)
  //   }
  // }, [dispatch, users])

  // useEffect(() => {
  //   setContact(users)
  //   setIsEdit(false)
  // }, [users])

  // useEffect(() => {
  //   if (!isEmpty(users) && !!isEdit) {
  //     setContact(users)
  //     setIsEdit(false)
  //   }
  // }, [users])

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = arg => {
    const user = arg

    setContact({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    })
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  // const onClickDelete = users => {
  //   setContact(users)
  //   setDeleteModal(true)
  // }

  const handleDeleteUser = () => {
    if (contact && contact.id) {
      dispatch(onDeleteUser(contact.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="ORGANIZATION" />
          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={apiData ? apiData : []}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}

            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit User" : "Add User"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Organization Id</Label>
                        <Input
                          name="organization_id"
                          placeholder="Insert orgaization id"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>{" "}
                      <div className="mb-3">
                        <Label className="form-label">Organization Name</Label>
                        <Input
                          name="organization_name"
                          placeholder="Insert orgaization name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Description</Label>
                        <Input
                          name="description"
                          placeholder="Insert description"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.designation &&
                            validation.errors.designation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.designation &&
                        validation.errors.designation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.designation}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Institution Id</Label>
                        <Input
                          name="institution_id"
                          placeholder="Insert institution id"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Institution Name</Label>
                        <Input
                          name="institution_name"
                          placeholder="Insert institution name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Logo</Label>
                        <Input
                          name="organization_logo"
                          placeholder="Insert logo"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Initial Credit</Label>
                        <Input
                          name="initial_credit"
                          placeholder="Insert initial credit"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Credit Description</Label>
                        <Input
                          name="credit_description"
                          placeholder="Insert credit description"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OrganizationsList
