import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Container } from "reactstrap"
import { Button, Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap"
import { Link } from "react-router-dom"
import profile1 from "assets/images/profile-img.png"

import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import WelcomeComp from "./WelcomeComp"
import TableContainerWithModal from "components/Common/TableContainerWithModal"
import { DateCell, TextCell } from "pages/CreditLog/creditLogCol"
import { IdCell } from "pages/AllDiplomaList/diplomaListCol"
import { StatusCell } from "pages/OrganizationList/organizationLogCol"
import { getAllContracts, getAllUsers } from "helpers/api_helper"
import TopCities from "./TopCities"
import TableContainer from "components/Common/TableContainer"

const SuperAdminDashboard = props => {
  //meta title
  document.title = "Dashboard"

  const [contracts, setContracts] = useState([])
  const [users, setUsers] = useState([])

  const fetchContracts = async () => {
    try {
      const r = await getAllContracts()
      const err = r
      if (err?.message != "error") {
        setContracts(r.data)
      }
    } catch (error) {
      return "error"
    }
  }

  const fetchUsers = async () => {
    try {
      const r = await getAllUsers()
      const err = r
      if (err?.message != "error") {
        setUsers(r.data)
      }
    } catch (error) {
      return "error"
    }
  }

  useEffect(() => {
    fetchContracts()
    fetchUsers()
  }, [])
  const organizationColumns = useMemo(
    () => [
      {
        Header: "Transaction Date",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <DateCell {...cellProps} />
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Hash",
        accessor: "pdfHash",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
    ],
    []
  )

  const userColumns = useMemo(
    () => [
      {
        Header: "Created Date",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <DateCell {...cellProps} />
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Username",
        accessor: "username",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <TextCell {...cellProps} />
            </>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="4">
              {/* <WelcomeComp /> */}
              <Col>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Total contracts</p>
                        <h4 className="mb-0">{contracts.length}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + "bx-copy-alt" + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Total users</p>
                        <h4 className="mb-0">{users.length}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + "bx-user" + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Card>
                <CardBody>
                  <div className="mb-4 h4 card-title">User List</div>
                  <TableContainer
                    columns={userColumns}
                    data={users ? users : []}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    isPagination={true}
                    isShowingPageLength={true}
                    iscustomPageSizeOptions={false}
                    customPageSize={5}
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Card>
                <CardBody>
                  <div className="mb-4 h4 card-title">Contract List</div>
                  <TableContainer
                    columns={organizationColumns}
                    data={contracts ? contracts : []}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    isPagination={true}
                    iscustomPageSizeOptions={false}
                    customPageSize={15}
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SuperAdminDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(SuperAdminDashboard)
