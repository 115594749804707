import axios from "axios"
import Cookies from "js-cookie"

const loginHandler = async values => {
  const data = values

  try {
    const enroll = await axios.post(
      `${process.env.REACT_APP_ROOT_API}/auth/login`,
      {
        username: data.username,
        password: data.password,
      }
    )
    Cookies.set("bearerToken", enroll.data.access_token)
    return enroll
  } catch (error) {
    return "error"
  }
}

export default loginHandler
