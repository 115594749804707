import axios from "axios"
import Cookies from "js-cookie"
import axiosRetry from "axios-retry"
import { error } from "toastr"

const API_URL = `${process.env.REACT_APP_ROOT_API}`

axiosRetry(axios, {
  retries: 3,
})

export async function getAllContracts() {
  const token = Cookies.get("")
  try {
    const response = await axios.get(`${API_URL}/contract`)
    return response
  } catch (error) {
    return { message: "error" }
  }
}

export async function getAllUsers() {
  const token = Cookies.get("")
  try {
    const response = await axios.get(`${API_URL}/user`)
    return response
  } catch (error) {
    return { message: "error" }
  }
}

export async function getPdf(value) {
  const token = Cookies.get("bearer_token")
  try {
    const response = await axios.get(`${API_URL}${value}`, {
      responseType: "blob",
    })
    return response
  } catch (error) {
    return error
  }
}

export async function signPdf(value) {
  const token = Cookies.get("bearer_token")
  const data = {
    id: value,
  }
  try {
    console.log(value)
    const response = await axios.post(
      `${API_URL}/contract/sign`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    return response
  } catch (error) {
    return error
  }
}

export async function uploadLogo(file) {
  const token = Cookies.get("bearerToken")
  const formData = new FormData()
  formData.append("file", file)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ROOT_API}/service-api/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}
