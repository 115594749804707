import Cookies from "js-cookie"
import { string } from "prop-types"
import React from "react"
import { Navigate, redirect } from "react-router-dom"

const Authmiddleware = props => {
  const bearerToken = Cookies.get("bearer_token")
  // console.log(bearerToken)
  // console.log(typeof Cookies.get("bearer_token") !== "string" ? true : false)
  if (typeof bearerToken !== "string") {
    return <Navigate to="/login" replace />
  } else if (!props.isPrivileged && typeof bearerToken === "string")
    return <React.Fragment>{props.children}</React.Fragment>
  else if (props.isPrivileged && typeof bearerToken === "string") {
    return <React.Fragment>{props.children}</React.Fragment>
  }
}

export default Authmiddleware
