import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import { Document, Page, pdfjs } from "react-pdf"
import { getPdf, signPdf } from "helpers/api_helper"
import toast from "react-hot-toast" // Import toast and Toaster
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const DiplomaDetailsModal = props => {
  const { data, index, isOpen, toggle } = props
  const [pdfFile, setPdfFile] = useState(null)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [metadata, setMetadata] = useState(null)
  const [containerWidth, setContainerWidth] = useState(0);

  const fetchUploadedPdf = async () => {
    if (data && Array.isArray(data) && data.length > index && data[index]) {
      try {
        const url = data[index].pdfFilePath
        const correctedUrl = url.replace(/\.\/uploads/, "/uploads")

        const r = await getPdf(correctedUrl)
        if (r.statusText == "OK") {
          setPdfFile(r.data)
        }
      } catch (error) {
        return error
      }
    }
  }

  useEffect(() => {
    setMetadata(data[index])
    fetchUploadedPdf()
  }, [data, index, isOpen])
  const handleSignPdf = async () => {
    console.log(data[index].id)
    const r = await signPdf(data[index].id)
    console.log(r)
    if (r.status == "Verified") {
      toast.success(`${r.statusMsg}`, {
        style: { fontSize: "16px" },
      })
    } else if (r == undefined) {
      toast.error(`Failed`, {
        style: { fontSize: "16px" },
      })
    }
  }
  useEffect(() => {
    setContainerWidth(window.innerWidth * 0.3);
    const handleResize = () => {
      setContainerWidth(window.innerWidth * 0.3);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  const previousPage = () => {
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1))
  }

  // Function to go to the next page
  const nextPage = () => {
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages))
  }
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      style={{ maxWidth: "90%", width: "90%" }}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>PDF</ModalHeader>
        <ModalBody>
          <div className="d-flex">
            {metadata && (
              <div className="" style={{ flex: '25%', margin: '0 20px' }}>
                <div className="p-4 text-center mb-5" style={{ backgroundColor: 'rgb(222 247 236 / 1', borderRadius: '0.5rem' }}>
                  <div style={{ fontWeight: 500, color: 'rgb(17 24 39 / 1)', fontSize: '1rem', lineHeight: '1.5rem' }}>{metadata.status}</div>
                </div>
                <div className="border rounded-4 py-3 px-5 overflow-auto" style={{ height: '50%' }}>
                  <h3 style={{ color: 'rgb(107 114 128 / 1', fontSize: '1.125rem' }}>Баталгаажуулсан мэдээлэл</h3>
                  <hr></hr>
                  <label style={{ color: 'rgb(17 24 39 / 1' }}>Талууд</label>
                  <hr></hr>
                  {metadata.actors.map((actor, idx) => (
                    <div className="border rounded-3 p-2 mb-2" key={idx}>
                      <div>
                        <label style={{ color: 'rgb(156 163 175 / 1' }}>Оролцогч тал</label>
                        <div>{actor.isOrg ? 'Байгууллага' : 'Хувь хүн'}</div>
                        <hr></hr>
                      </div>
                      <div>
                        <label style={{ color: 'rgb(156 163 175 / 1' }}>Нэр</label>
                        <div>{actor.name}</div>
                        <hr></hr>
                      </div>
                      <div>
                        <label style={{ color: 'rgb(156 163 175 / 1' }}>Цахим гарын үсэг</label>
                        <div>{actor.signature}</div>
                        <hr></hr>
                      </div>
                      <div>
                        <label style={{ color: 'rgb(156 163 175 / 1' }}>Цахим гарын үсэг үүссэн огноо</label>
                        <div>{actor.signatureDate.slice(0, 10)}</div>
                        <hr></hr>
                      </div>
                      {(actor.mobileNo !== 0 && actor.mobileNo) && (
                        <div>
                          <label style={{ color: 'rgb(156 163 175 / 1' }}>Утасны дугаар</label>
                          <div>{actor.mobileNo}</div>
                          <hr></hr>
                        </div>
                      )}
                      {(actor.address !== '' && actor.address) && (
                        <div>
                          <label style={{ color: 'rgb(156 163 175 / 1' }}>Хаяг</label>
                          <div>{actor.address}</div>
                          <hr></hr>
                        </div>
                      )}
                      <div>
                        <label style={{ color: 'rgb(156 163 175 / 1' }}>Үүрэг</label>
                        <div>{actor.role}</div>
                      </div>
                    </div>
                  )
                  )}
                  <div>
                    <label style={{ color: 'rgb(156 163 175 / 1' }}>Гэрээний төрөл</label>
                    <div>{metadata.type}</div>
                    <hr></hr>
                  </div>
                  <div>
                    <label style={{ color: 'rgb(156 163 175 / 1' }}>Гэрээний ID</label>
                    <div>{metadata.pdfHash}</div>
                    <hr></hr>
                  </div>
                  <div>
                    <label style={{ color: 'rgb(156 163 175 / 1' }}>Баталгаажсан огноо</label>
                    <div>{metadata.createdAt.slice(0, 10)}</div>
                  </div>
                  <hr></hr>
                </div>
              </div>
            )}
            <div className="d-flex bg-light justify-content-center pt-2" style={{ flex: '75%', borderRadius: '20px' }}>
              {pdfFile && (
                <Document
                  className=""
                  file={pdfFile}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    width={containerWidth}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    pageNumber={pageNumber}
                  />
                </Document>
              )}</div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-right">
          <div className="">
            <button
              onClick={previousPage}
              disabled={pageNumber <= 1}
              className="btn btn-primary btn-block"
              type="submit"
            >
              Өмнөх
            </button>

            <span className="mx-4">
              Хуудас {pageNumber} / {numPages}
            </span>
            <button
              onClick={nextPage}
              disabled={pageNumber >= numPages}
              className="btn btn-primary btn-block"
              type="submit"
            >
              Дараах
            </button>
          </div>
          {/* <Button type="button" color="primary" onClick={handleSignPdf}>
            Sign
          </Button> */}
        </ModalFooter>
      </div >
    </Modal >
  )
}

DiplomaDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default DiplomaDetailsModal
