import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"

import { Button, Card, CardBody, Container } from "reactstrap"

import { TextCell, DateCell, IdCell, StatusCell } from "./diplomaListCol"
import TableContainerWithModal from "components/Common/TableContainerWithModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getAllContracts } from "helpers/api_helper"

const AllDiplomaList = props => {
  document.title = "All Contract List"

  const [apiData, setApiData] = useState(null)

  const fetchCredits = async () => {
    try {
      const r = await getAllContracts()
      const err = r
      if (err?.statusText == "OK") {
        setApiData(r.data)
      }
    } catch (error) {
      return "error"
    }
  }
  useEffect(() => {
    fetchCredits()
  }, [])
  const [modal1, setModal1] = useState(false)

  const toggleViewModal = () => setModal1(!modal1)
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateCell {...cellProps} />
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <IdCell {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Hash",
        accessor: "pdfHash",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <StatusCell {...cellProps} />
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Latest Uploads"
            breadcrumbItem="ALL CONTRACT LIST"
          />
          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">Latest Uploads</div>
              <TableContainerWithModal
                columns={columns}
                data={apiData ? apiData : []}
                isGlobalFilter={true}
                isAddOptions={false}
                isPagination={true}
                iscustomPageSizeOptions={true}
                customPageSize={10}
                pagination="pagination pagination-rounded justify-content-end mb-2"
                modalState={modal1}
                toggleViewModal={toggleViewModal}
                isUpload={false}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

AllDiplomaList.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default AllDiplomaList
