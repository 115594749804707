import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Index from "../pages/Index"

// Dashboard
import Dashboard from "../pages/SuperAdminDashboard/index"
import CreditLog from "pages/CreditLog/credits-list"
import OrganizationsList from "pages/OrganizationList/organizations-list"
import AllDiplomaList from "pages/AllDiplomaList/all-diploma-list"

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
]

const superAdminRoutes = [
  { path: "/admin-dashboard", component: <Dashboard /> },
  { path: "/organizations-list", component: <OrganizationsList /> },
  { path: "/credit-log", component: <CreditLog /> },
  { path: "/all-diploma-list", component: <AllDiplomaList /> },
]

const constRoutes = [
  {
    verbose: "",
    path: "/",
    exact: true,
    component: <Index />,
  },
]

export { superAdminRoutes, constRoutes, publicRoutes }
