import Cookies from "js-cookie"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default function Index() {
  const bearerToken = Cookies.get("bearer_token")
  const navigate = useNavigate()
  useEffect(() => {
    if (typeof bearerToken === "string") {
      navigate("/admin-dashboard")
    } else if (typeof bearerToken !== "string") {
      navigate("/login")
    }
  }, [])

  return <></>
}
